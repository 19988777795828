<template>
  <div class="gtm-base__redirection-page">
    <home-skeleton v-if="skeletonShow"/>
  </div>
</template>

<script>
import HomeSkeleton from '@/views/platform/skeleton-page/HomeSkeleton'
import { mixtureMenu } from '@/menu'
import { rootState } from '@gtm-butils'

export default {
  name: 'RedirectionPage',
  components: { HomeSkeleton },
  mixins: [],
  props: {},
  data () {
    return {
      skeletonShow: false
    }
  },
  async mounted () {
    // projectCode&sectionCode代表需要请求项目信息
    let { token, projectCode, sectionCode, pageName, productCode } = this.$route.query
    let goToType = 'login'
    if (pageName) {
      goToType = 'pageName'
    }
    if (projectCode && sectionCode) {
      // 校验对接交易的参数
      sessionStorage.setItem('portal_root_projectCode', projectCode)
      sessionStorage.setItem('portal_root_sectionCode', sectionCode)
      sessionStorage.setItem('portal_root_productCode', productCode)
      console.log('【base-platform】【redirection】参数校验通过', this.$route.query)
    }

    if (token) {
      if (!sessionStorage.getItem('TENANT_DOMAIN')) {
        await this.$baseAppApi.USER.fetchGetUserTenant({ token })
          .then(res => {
            rootState.tenantInfo = res.data || {}
            sessionStorage.setItem('TENANT_DOMAIN', res?.data?.domain || '')
            sessionStorage.setItem('TENANT_ORIGIN_DOMAIN', res?.data?.originDomain || '')
            console.log('【base-platform】【Redirection.vue】获取租户原始域名：', res?.data?.domain || '')
          })
      }
      this.requestCommonLogin(goToType)
    }
  },
  methods: {
    // 拿到token后，做权限认证
    requestCommonLogin (goToType = 'login') {
      let params = {
        token: this.$route.query.token
      }
      this.skeletonShow = true
      this.$guaranteeApi.COMMON_LOGIN.fetchToLogin(params, params)
        .then(async res => {
          if (res.code === 0) {
            this.$gtmUtils.setCookie('SESSION_TOKEN', this.$route.query.token)
            // 登录后，优先判断有没有选角色，如果没选，进入选角色页面
            const res = await this.$baseAppApi.USER.fetchUserHasSelectedRole()
            const isUserRoleSelect = res?.data
            if (isUserRoleSelect === false || isUserRoleSelect === 'false') {
              this.$router.replace({
                path: `/platform/userRole`,
                query: { token: this.$route.query.token }
              })
            } else {
              // 如果选定角色，调用公共上下文进入登录后的页面
              await this.$rootMethods.APP_CONTEXT.initAppContext()
                .finally(() => {
                  console.log('【base-platform】【Platform.vue】子应上下文数据：', this.$rootState)
                  this.$store.state.rootMicroAppData = this.$rootState
                  const indexInfo = this.$rootState.indexInfo
                  if (goToType === 'login') {
                    this.loginGoToPage(indexInfo)
                  }
                  if (goToType === 'pageName') {
                    this.pageNameGoToPage()
                  }
                })
            }
          } else {
            this.skeletonShow = false
            window.parent.postMessage(
              {
                type: 'router',
                action: 'interfaceNoAuth'
              },
              '*'
            )
          }
        })
    },
    // 登录后跳转的页面
    loginGoToPage (indexInfo) {
      console.log(
        `%c当前跳转地址为：gtm/web/platform/redirection?token=${this.$route.query.token}`,
        'color:orangered;font-size:20px;'
      )
      if (indexInfo?.sysInfo?.webUrl && indexInfo?.sysInfo?.webUrl !== '/portal/home') {
        this.$router.replace({
          path: `/platform${indexInfo?.sysInfo?.webUrl}`,
          query: { token: this.$route.query.token }
        })
      } else {
        this.$router.replace({
          path: this.$store.state.defaultMenuInfo.defaultPath
        })
      }
    },
    pageNameGoToPage () {
      let { pageName } = this.$route.query
      console.log(pageName)
      if (Array.isArray(pageName) && pageName.length > 0) {
        pageName = pageName[0]
      }
      console.log(pageName)
      const toMenu = mixtureMenu.find(it => `/${pageName}` === it.path)
      console.log(toMenu)
      this.$router.replace({
        path: `/platform/${toMenu.appName}${toMenu.path}`,
        query: this.$route.query
      })
    }
  }
}
</script>
<style lang="scss">
.gtm-base__redirection-page {
  width: 100%;
  height: 100%;

  .skeleton {
    height: 80%;
    padding: 20px;

    .el-skeleton {
      height: 100%;
    }
  }
}
</style>
